'use client';

import React from 'react';
import { useNotifications } from 'hooks/useNotifications';
import { ImportedEntriesNotification } from './imported-entries/ImportedEntriesNotification';
import { BankAccountMissingNotification } from './bank-account-missing/BankAccountMissingNotification';
import { UnpaidEntriesNotification } from './unpaid-entries/UnpaidEntriesNotification';
import { PendingEmailChangeNotification } from './pending-email-change/PendingEmailChangeNotification';
import { MissingIdentificationNotification } from './missing-identification/MissingIdentificationNotification';
import { UnreadMessageNotification } from './unread-message/UnreadMessageNotification';
import { VehicleRegistrationNotification } from './vehicle-registration/VehicleRegistrationNotification';
import { SystemMessageNotification } from './system-notification/SystemMessageNotification';

export const Notifications: React.FC = () => {
  const { notifications } = useNotifications();

  return (
    <>
      <UnreadMessageNotification latestUnreadMessage={notifications.data?.latestUnreadMessage} />
      <SystemMessageNotification systemNotification={notifications.data?.systemNotification} />
      <ImportedEntriesNotification importedEntries={notifications.data?.importedEntries} />
      <BankAccountMissingNotification isBankAccountMissing={notifications.data?.isBankAccountMissing} />
      <UnpaidEntriesNotification entries={notifications.data?.unpaidEntries} />
      <PendingEmailChangeNotification pendingEmailChange={notifications.data?.pendingEmailChange} />
      <MissingIdentificationNotification isIdentificationMissing={notifications.data?.isIdentificationMissing} />
      <VehicleRegistrationNotification entries={notifications.data?.vehicleRegistrations} />
    </>
  );
};
