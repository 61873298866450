import React from 'react';
import { Box } from '@mezzoforte/forge';
import { Container } from 'components/Container/Container';

const notificationColors = {
  'alert-danger': {
    bg: 'dangerDark',
    text: 'background',
  },
  'alert-warning': {
    bg: 'warning',
    text: 'background',
  },
  'alert-success': {
    bg: 'success',
    text: 'background',
  },
  'alert-info': {
    bg: 'lineBorder',
    text: 'text',
  },
} as const;

export interface SystemNotification {
  readonly message: string;
  readonly styleClass: keyof typeof notificationColors;
}

export interface SystemMessageNotificationProps {
  readonly systemNotification?: SystemNotification | null;
}

export const SystemMessageNotification = ({ systemNotification }: SystemMessageNotificationProps) => {
  if (!systemNotification) {
    return null;
  }

  return (
    <Box
      py={1}
      backgroundColor={notificationColors[systemNotification.styleClass]?.bg ?? 'brand'}
      color={notificationColors[systemNotification.styleClass]?.text ?? 'Background'}
      data-test="system-notification"
      fontSize="sm"
      sx={{
        a: {
          textDecoration: 'underline',
        },
      }}
    >
      <Container dangerouslySetInnerHTML={{ __html: systemNotification.message }} />
    </Box>
  );
};
